<template>
	<Template
			v-bind="{
				...params,
				isLoading
			}"
			@onSubmit="() => onRegister()"
			@goTo="path => $router.push(path)"
	/>
</template>
<script>
import Template from '../components/templates/RegistrationView/SplitScreen/SplitScreen';
import {c} from '@/components/constants.js'

export default {
	components: {
		Template,
	},
	methods: {
		async onRegister() {
			this.params.inputs.email.error = null
			this.params.inputs.password.error = null
			const forms = this.params.inputs;
			console.log(forms, 'forms ====')
			if (!forms.email?.value) {
				this.params.inputs.email.error = 'Please insert email!'
				return false;
			}
			if (!forms.password?.value) {
				this.params.inputs.password.error = 'Please insert password!';
				return false;
			}

			try {
				this.isLoading = true;
				const user = await this.actions.createAppUser({ email: forms.email?.value, password: forms.password?.value, sendEmailVerification: true });
				console.log(user, 'user')
				if (user?.user?.id) {
					await this.actions.login({email: forms.email?.value, password: forms.password?.value, isRegister: true});
					if (this.state.isLoggedIn) {
						this.actions.alert.showSuccess({message: 'Registered successfully!'});
						this.$router.push({name: 'Verify Email Step', query: {email: forms.email?.value}})
					}
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false;
			}
		}
	},
	data() {
		return {
			params: c.register,
			isLoading: false,
			isAgreed: false,
		}
	},
}
</script>
