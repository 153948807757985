<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false; $emit('onClose')">
			<div class="flex items-end justify-center text-center">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
					leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div
						class="fixed top-0 sm:top-8 inline-block sm:rounded-sm h-screen sm:h-7/8 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full ">
						<!-- <div class="flex-none"> -->
              <div class="cursor-pointer w-fit" @click="open = false; $emit('onClose')" >
                <Icon name="XIcon" size="6" color="gray-600" customClass="absolute right-4 top-4" />
              </div>
              <div class="flex mt-12 mb-4 px-4 sm:px-12">
                <Text size="4xl" weight="extrabold" color="slate-900" :content="title" />
              </div>
            <!-- </div> -->

            <div class="flex-1 h-full overflow-y-auto pb-36 sm:pb-12">
              <div class="flex flex-col sm:p-6 sm:px-12 px-4 pb-36 sm:pb-0">
                <div class="flex flex-col space-y-4">
                    <Text size="sm" color="gray-700" v-for ="(item,index) in content" :key="index" :content="item" weight="medium"></Text>
                </div>
						  </div>
            </div>
						<!-- <div class="flex-none"> -->
              <div  class="fixed w-full bottom-0 bg-gray-100 border-t h-auto py-4 px-8">
                <div class="flex justify-end ">
                  <Button :content="btnText" variant="primary"  :isNoHoverBorder="true" :click="() => $emit('onClose')" />
                </div>
						  </div>
            <!-- </div> -->

					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
/**
 * - Use it to show a simple modal.
 */
export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		Icon, Button, Text
	},
	props: {
		/**
		 * Use it for modal title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Use it for modal description
		 */
		content: {
			type: Array,
			default: () => []
		},
		/**
		 * Use it for yes button text
		 */
		open: {
			type: Boolean,
			default: true
		},

		/**
		 * Use it for no button text
		 */
		btnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for no buttona action
		 */
		onBtnClick: {
			type: Function,
			default: () => {}
		}
	},
}
</script>
