<template>
  <div :class="`flex flex-col flex-1${customClass}`">
     <div class="flex justify-between">
      <Text
        v-if="label"
        weight="semibold"
        size="sm"
        color="gray-700"
        custom-class="block"
        :content="label"
      />
      <Text
        v-if="hint"
        :id="`${id}-optional`"
        size="sm"
        color="gray-500"
        :content="hint"
      />
    </div>
    <div class="relative mt-1 rounded-md shadow-sm ">
      <div
        class="absolute inset-y-0 right-0 flex items-center px-3 border-r"
      >
        <Icon 
          :name="showPassword ? 'EyeOffIcon' : 'EyeIcon'" 
          is-outline color="gray-400" 
          customClass="flex-shrink-0 mr-1 cursor-pointer" 
          hoverTextColor="gray-500"
          size="5"
          @click="showPassword = !showPassword"
          />
       
      </div>
      <input
        id="passwordInput"
        name="passwordInput"
        :type="showPassword ? 'text' : 'password'"
        ref="inputField" 
        :readonly ="disabled" 
        v-model="inputValue"
        class="block w-full h-10 py-2 pr-10 pl-3 border border-gray-300 rounded-md sm:text-sm focus:outline-none focus:ring-1 focus:ring-indigo-500"
        :placeholder="placeholder"
        :class="`${disabled ? 'text-gray-500' : ''}`"
        style="min-width: 150px !important;"
        v-on:keyup.enter="onEnterPressed"
      />
    </div>
    <Text
      v-if="isError"
      id="`password-description`"
      size="sm"
      :content="helpText"
      color="red-600"
    />
  </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";

/**
 * - Use it if needs to show input form with leading icon
 * - icon is required
 */
export default {
  components: {
    Icon,
    Text
  },
  props: {
    /**
     * Use to show what input form is for
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of input form
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to input form
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of input form
     */
    value: {
      type: String,
      default: "",
    },
        /**
     * Use to show whether or not value is valid.
     */
    isError: {
      type: Boolean,
      default: false,
    },
    /**
     * Use to specify leading icon. its required. ex: MailIcon
     */
    disabled: {
      type: Boolean,
      default: false,
    },
      /**
     * function called on pressing enter
     */
    onEnterPressed: {
      type: Function,
      default: () => {}
    },
     /**
     * Use to hint users for input value
     */
    hint: {
      type: String,
      default: "",
    },
    /**
     * Use to help users to fill form out
     */
    helpText: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
  data() {
    return {
      showPassword: false
    }
  }
};
</script>
